define('c2b/components/select-validated/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['input-validated'],
    model: null,
    value: null,
    type: 'text',
    valuePath: '',
    title: null,
    placeholder: '',
    validation: null,
    didValidate: false,

    myErrors: Ember.computed('errors', 'valuePath', function () {
      if (!this.errors || !this.valuePath) return null;
      return this.get('errors.' + this.valuePath);
    }),

    noErrors: Ember.computed.not('myErrors').readOnly(),

    hasContent: Ember.computed.notEmpty('value').readOnly(),

    isValid: Ember.computed.and('hasContent', 'didValidate', 'noErrors').readOnly(),
    isInvalid: Ember.computed.and('didValidate', 'myErrors').readOnly(),

    init() {
      this._super(...arguments);
      let valuePath = this.get('valuePath');

      Ember.defineProperty(this, 'validation', Ember.computed.readOnly(`model.validations.attrs.${valuePath}`));
      Ember.defineProperty(this, 'value', Ember.computed.alias(`model.${valuePath}`));
    },

    focusOut() {
      this._super(...arguments);
    },

    actions: {
      onChange(select) {
        if (this.model && this.valuePath) this.set('model.' + this.valuePath, select.target && select.target.value);
      }
    }
  });
});