define('c2b/details/route', ['exports', 'c2b/commands/CallFunction', 'c2b/commands/DecodeSessionToken'], function (exports, _CallFunction, _DecodeSessionToken) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    metrics: Ember.inject.service(),

    async model() {
      let [design, details] = await Promise.all([_CallFunction.default.get('getDesign?page=details'), _CallFunction.default.get('getDetails')]);
      details = details || {};
      details.validations = null;
      design = design || {};

      let session = _DecodeSessionToken.default.call();
      this.metrics.invoke('identify', 'GoogleAnalytics', { distinctId: session.uid });

      return {
        details: Ember.Object.create(details),
        design: design.data
      };
    }

  });
});