define('c2b/initializers/error-control', ['exports', 'error-control', 'stackdriver-error-reporter', 'c2b/commands/DecodeSessionToken', 'c2b/config/environment'], function (exports, _errorControl, _stackdriverErrorReporter, _DecodeSessionToken, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;


  class StackDriverErrorReporting {

    constructor(stackDriver) {
      this.stackDriver = stackDriver;
    }

    report(aError) {
      this.stackDriver.report(aError);
    }
  }

  class AppExceptionFilter {

    filter(aError) {
      if (aError.statusCode == 401) {
        return new _errorControl.UserError("Unauthorised. Please open the link in the text message again.", aError.statusCode, aError);
      } else {
        return aError;
      }
    }
  }

  class ConsoleErrorReporter {
    report(aError, aUser = null) {
      console.dir(aError);
    }
  }

  class StandardExceptionWrappingFilter {
    filter(aError) {
      if (aError instanceof _errorControl.StandardException) return aError;
      return new _errorControl.StandardException(aError.message, null, aError);
    }
  }

  function initialize() /* application */{

    let stackdriver;

    if (_environment.default.stackdriver && _environment.default.stackdriver.errorsKey) {
      let session = _DecodeSessionToken.default.call();
      stackdriver = new _stackdriverErrorReporter.default();
      stackdriver.start({
        key: _environment.default.stackdriver.errorsKey,
        projectId: 'connect2bet',
        service: session && session.aud || null, // (optional)
        //version: '<my-service-version>',            // (optional)
        // reportUncaughtExceptions: false          // (optional) Set to false to stop reporting unhandled exceptions.
        // reportUnhandledPromiseRejections: false  // (optional) Set to false to stop reporting unhandled promise rejections.
        // disabled: true                           // (optional) Set to true to not report errors when calling report(), this can be used when developping locally.
        context: { user: session && session.sub || null //, entry_id: (session && session.entry_id) || null, name: (session && session.name) || null}                 // (optional) You can set the user later using setUser()
        } });
    }

    _errorControl.ErrorControl.default.appendReporter(new ConsoleErrorReporter());
    if (stackdriver) _errorControl.ErrorControl.default.appendReporter(new StackDriverErrorReporting(stackdriver));
    _errorControl.ErrorControl.default.appendFilter(new StandardExceptionWrappingFilter());
    _errorControl.ErrorControl.default.appendFilter(new AppExceptionFilter());
  }

  exports.default = {
    initialize
  };
});