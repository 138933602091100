define('c2b/voucher/route', ['exports', 'c2b/commands/CallFunction', 'error-control/dist/es/StandardException'], function (exports, _CallFunction, _StandardException) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    async model() {
      let response = await _CallFunction.default.get('getVoucher', null, e => e.statusCode == 403 ? new _StandardException.UserError('The voucher is no longer available.', e.statusCode, e) : e);
      let voucher = response && response.data;
      let design = (await _CallFunction.default.get('getDesign?page=voucher')) || {};
      return {
        voucher: voucher,
        design: design.data
      };
    }
  });
});