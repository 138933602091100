define('c2b/components/panel-layer-two/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['PanelLayerTwo'],
    classNameBindings: ['name']
  });
});