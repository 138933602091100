define('c2b/initializers/on-error', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(aApplication) {
    // application.inject('route', 'foo', 'service:foo');

    var application = aApplication;

    Ember.onerror = function (e) {

      var container = application.__container__;

      var es = container.lookup('service:error-service');

      console.log(e.message);
      es.report(e);
    };
  }

  exports.default = {
    initialize
  };
});