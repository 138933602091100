define('c2b/components/claimed-button/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    tagName: '',
    warning: false,
    warningBegan: null,
    onConfirm: null,

    busy: false,

    actions: {

      // watchClock: computed('clock.second', function () {
      //   if (this.warning) {
      //     let delay = (new Date()) - this.warningBegan;
      //     if (this.delay > 5000)
      //       this.warning = false;
      //   }
      //   return this.clock.second;
      // }),

      clicked() {
        if (!this.warning) {
          this.set('warning', true);
          Ember.run.later(this, function () {
            if (this.isDestroyed) return;
            this.set('warning', false);
          }, 5000);
        } else {
          if (this.onConfirm) {
            this.set('busy', true);
            let response = this.onConfirm();
            const finalizer = () => !this.isDestroyed && this.set('busy', false);
            if (response instanceof Promise) {
              response.then(finalizer, finalizer);
            } else {
              finalizer();
            }
          }
        }
      }

    }

  });
});