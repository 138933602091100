define('c2b/decline/controller', ['exports', 'c2b/commands/CallFunction'], function (exports, _CallFunction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    layout: Ember.computed('model.design.decline.layout', function () {
      return this.get('model.design.decline.layout');
    })
  });
});