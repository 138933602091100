define('c2b/claimed/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    began: null,

    reset() {
      this.set('began', null);
    },

    count: Ember.computed('clock.second', function () {
      let justStarted = !this.began;
      if (justStarted) {
        this.set('began', this.clock.second);
      }
      let maxCount = 15;
      let subtraction = (this.clock.second - this.began + 60) % maxCount;
      if (subtraction == 0) {
        if (!justStarted) {
          this.transitionToRoute('promotion');
          // } else {
          //   this.set('justStarted', false);
        }
      }
      return maxCount - subtraction;
    })

  });
});