define('c2b/decline/route', ['exports', 'c2b/commands/CallFunction'], function (exports, _CallFunction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    async model() {
      let design = (await _CallFunction.default.get('getDesign?page=decline')) || {};
      return {
        design: design.data
      };
    }
  });
});