define('c2b/details/controller', ['exports', 'lodash', 'c2b/commands/CallFunction', 'error-control/dist/es/UserErrors'], function (exports, _lodash, _CallFunction, _UserErrors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    busy: false,
    errors: null,
    validated: false,

    layout: Ember.computed('model.design.details.layout', function () {
      return this.get('model.design.details.layout');
    }),

    reset() {
      this.set('busy', false);
    },

    actions: {
      async submitDetails() {
        let data = _lodash.default.pick(this.model.details, Object.keys(this.model.details));

        let result;
        try {
          this.set('busy', true);
          result = await _CallFunction.default.call('receiveDetails', { data: data }, e => {
            if (e.statusCode == 422) // this causes the exception to be swallowed and the response to be returned instead
              return null;
          });
          if (this.isDestroyed) return;

          this.set('validated', true);
          if (result && result.success) {
            if (result.showVoucher) this.transitionToRoute('voucher');else this.transitionToRoute('promotion');
          } else if (result && result.errors && Object.keys(result.errors).length) {
            this.set('errors', result.errors);
            this.set('busy', false);
          } else this.set('busy', false);
        } catch (e) {
          if (!this.isDestroyed) this.set('busy', false);
          throw e;
        }
      }
    }
  });
});