define('c2b/voucher/controller', ['exports', 'c2b/commands/CallFunction'], function (exports, _CallFunction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    layout: Ember.computed('model.design.voucher.layout', function () {
      return this.get('model.design.voucher.layout');
    }),

    actions: {
      async claimed() {
        let result = await _CallFunction.default.call('voucherClaimed');
        if (result.success) this.transitionToRoute('claimed');
      }
    }
  });
});