define('c2b/components/terms-input/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['terms-input'],
    // classNameBindings: ['showErrorClass:has-error', 'isValid:has-success'],
    model: null,
    value: null,
    type: 'text',
    valuePath: '',
    title: 'Terms and Conditions',
    label: 'I have read and accept the Terms and Conditions',
    modalTitle: 'Terms and Conditions',
    modalText: null,
    placeholder: '',
    validation: null,
    //showValidations: false,
    didValidate: false,

    myErrors: Ember.computed('errors', 'valuePath', function () {
      if (!this.errors || !this.valuePath) return null;
      return this.get('errors.' + this.valuePath);
    }),

    noErrors: Ember.computed.not('myErrors').readOnly(),

    //notValidating: not('validation.isValidating').readOnly(),
    hasContent: Ember.computed.notEmpty('value').readOnly(),
    //hasWarnings: notEmpty('validation.warnings').readOnly(),

    isValid: Ember.computed.and('hasContent', 'didValidate', 'noErrors').readOnly(),
    isInvalid: Ember.computed.and('didValidate', 'myErrors').readOnly(),

    // shouldDisplayValidations: or(
    //   'showValidations',
    //   'didValidate',
    //   'hasContent'
    // ).readOnly(),
    // showErrorClass: and(
    //   'notValidating',
    //   'showErrorMessage',
    //   'hasContent',
    //   'validation'
    // ).readOnly(),
    // showErrorMessage: and(
    //   'shouldDisplayValidations',
    //   'validation.isInvalid'
    // ).readOnly(),
    // showWarningMessage: and(
    //   'shouldDisplayValidations',
    //   'hasWarnings',
    //   'isValid'
    // ).readOnly(),

    init() {
      this._super(...arguments);
      let valuePath = this.get('valuePath');

      Ember.defineProperty(this, 'validation', Ember.computed.readOnly(`model.validations.attrs.${valuePath}`));
      Ember.defineProperty(this, 'value', Ember.computed.alias(`model.${valuePath}`));
    },

    focusOut() {
      this._super(...arguments);
      //this.set('showValidations', true);
    }
  });
});