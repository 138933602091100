define('c2b/router', ['exports', 'c2b/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    metrics: Ember.inject.service(),
    rootURL: _environment.default.rootURL,

    init() {
      this._super(...arguments);
      this.on('routeDidChange', transition => {
        this._trackPage();
      });
    },

    // ga.send('pageView', {
    //   pageName: transition.to.name
    // });

    _trackPage() {
      Ember.run.scheduleOnce('afterRender', this, () => {
        const page = this.get('url');
        const title = this.getWithDefault('currentRouteName', 'unknown');
        Ember.get(this, 'metrics').trackPage({ page, title });
      });
    }
  });

  Router.map(function () {
    this.route('details');
    this.route('decline');
    this.route('voucher');
    this.route('claimed');
    this.route('promotion');
    this.route('application-error');
    this.route('voucher-error');
    this.route('signup');

    this.route('throw_error', { path: '/throw_error/:code' });
  });

  exports.default = Router;
});