define('c2b/application-error/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    setupController(controller, error) {
      console.log(error.message);
      this._super(...arguments);
    }

  });
});