define('c2b/components/details-form/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      submitDetails() {
        this.controller.send('submitDetails');
      }
    }
  });
});