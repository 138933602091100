define('c2b/promotion/controller', ['exports', 'c2b/commands/CallFunction'], function (exports, _CallFunction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    layout: Ember.computed('model.design.promotion.layout', function () {
      return this.get('model.design.promotion.layout');
    }),

    actions: {
      signup() {
        let destination = this.get('model.destination');
        if (destination) window.location.assign(destination);
      }
    }
  });
});