define('c2b/application/exceptions', ['exports', 'error-control'], function (exports, _errorControl) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.BadResponseError = undefined;


	class BadResponseError extends _errorControl.FrontEndError {
		constructor(aMessage = null, statusCode = null, inner = null, data = []) {
			super(aMessage || BadResponseError.MESSAGE, statusCode || BadResponseError.STATUS_CODE, inner, data);
		}
	}
	BadResponseError.MESSAGE = 'The server returned a response with bad syntax.';
	BadResponseError.STATUS_CODE = 400;

	exports.BadResponseError = BadResponseError;
});