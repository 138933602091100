define('c2b/services/clock', ['exports', 'c2b/config/environment', 'ember-clock/services/clock'], function (exports, _environment, _clock) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const isDisabled = _environment.default['ember-clock'] && _environment.default['ember-clock'].disabled;
  exports.default = _clock.default.extend({
    disabled: isDisabled
  });
});