define('c2b/signup/controller', ['exports', 'lodash', 'c2b/commands/CallFunction'], function (exports, _lodash, _CallFunction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    busy: false,
    errors: null,
    validated: false,

    reset() {
      this.set('busy', false);
    },

    actions: {
      async submitForm() {
        let data = _lodash.default.pick(this.model, Object.keys(this.model));

        let result;
        try {
          this.set('busy', true);
          result = await _CallFunction.default.call('signup', { data: data }); // !!! should rewrite this based on details/controller
          this.set('validated', true);
          let validationErrors = result.errors || {};
          if (Object.keys(validationErrors).length) {
            this.set('errors', validationErrors);
            if (!this.isDestroyed) this.set('busy', false);
          } else {
            if (result.destination) window.location.assign(result.destination);
          }
        } catch (e) {
          if (!this.isDestroyed) this.set('busy', false);
        }
      }
    }
  });
});