define('c2b/services/error-service', ['exports', 'error-control'], function (exports, _errorControl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {

    isServiceFactory: true,

    create(context) {
      return _errorControl.ErrorControl.default;
    }
  };
});