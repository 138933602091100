define('c2b/design/service', ['exports', 'fetch', 'c2b/commands/CallFunction'], function (exports, _fetch, _CallFunction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    data: null,

    // configure(aName) {
    //   if (this.name && this.name!=aName)
    //     throw new Error("Can't configure design more than once with different names");
    //   this.name = aName;
    // },

    async getData() {
      // if (!this.name)
      //   throw new Error('design service not configured');
      if (this.data) return this.data;
      let data = await _CallFunction.default.call('getDesign');
      this.data = data && data.data;
      return data;
    }

  });
});