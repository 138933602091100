define('c2b/commands/DecodeSessionToken', ['exports', 'ember-jwt-decode'], function (exports, _emberJwtDecode) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = class {

    static call() {
      let allCookiesStr = document.cookie;
      if (!allCookiesStr) return null;
      let cookies = allCookiesStr.split(';').reduce((cookieObject, cookieString) => {
        let splitCookie = cookieString.split('=').map(cookiePart => cookiePart.trim());
        try {
          cookieObject[splitCookie[0]] = JSON.parse(splitCookie[1]);
        } catch (error) {
          cookieObject[splitCookie[0]] = splitCookie[1];
        }
        return cookieObject;
      }, {});
      // let result = {};
      // for (let rc of allCookiesStr.split('; ')) {
      //   let c = Cookie.parse(rc);
      //   result[c.key] = c;
      // }
      // return result;

      if (!cookies.__session) return null;

      let result = (0, _emberJwtDecode.default)(cookies.__session);

      return result;
    }
  };
});