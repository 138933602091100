define('c2b/initializers/clock', ['exports', 'ember-clock/initializers/clock'], function (exports, _clock) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _clock.default;
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function () {
      return _clock.initialize;
    }
  });
});