define('c2b/initializers/metrics', ['exports', 'c2b/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() {
    const application = arguments[1] || arguments[0];
    const { metricsAdapters = [] } = _environment.default;
    const { environment = 'development' } = _environment.default;
    const options = { metricsAdapters, environment };

    application.register('config:metrics', options, { instantiate: false });
    application.inject('service:metrics', 'options', 'config:metrics');
  }

  exports.default = {
    name: 'metrics',
    initialize
  };
});