define('c2b/components/voucher-layout-demo/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    controller: null,
    design: null

  });
});