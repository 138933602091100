define('c2b/helpers/markdown', ['exports', 'micromarkdown'], function (exports, _micromarkdown) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.markdown = markdown;
  function markdown([value, ...rest]) {
    let result = _micromarkdown.default.parse(value);
    return result;
  }

  exports.default = Ember.Helper.helper(markdown);
});