define('c2b/application-error/controller', ['exports', 'error-control/dist/es/StandardException'], function (exports, _StandardException) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    errorService: Ember.inject.service('error-service'),

    title: Ember.computed('model', function () {
      return this.isUserError ? null : this.model.human_name || this.model.toString();
    }),

    message: Ember.computed('model', function () {
      return this.model.human_message || this.model.message;
    }),

    isUserError: Ember.computed('model', function () {
      return this.model instanceof _StandardException.UserError;
    }),

    setup() {
      this.errorService.report(this.model);
    }

  });
});