define('c2b/initializers/controller-lifecycle', ['exports', 'ember-controller-lifecycle/initializers/controller-lifecycle'], function (exports, _controllerLifecycle) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _controllerLifecycle.default;
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function () {
      return _controllerLifecycle.initialize;
    }
  });
});