define("c2b/commands/CallFunction", ["exports", "fetch", "c2b/application/exceptions", "error-control/dist/es/UserErrors", "c2b/application/regulated_fetch"], function (exports, _fetch, _exceptions, _UserErrors, _regulated_fetch) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = class {

    static async rpc_fetch(request, options, aPostFilter = null) {
      let response = await (0, _regulated_fetch.default)(request, options, aPostFilter);
      let result = null;
      try {
        if (response.status != 204) // no content
          result = response.headers.get('Content-Type').indexOf('json') >= 0 ? await response.json() : await response.text();
      } catch (e) {
        throw new _exceptions.BadResponseError(null, null, e);
      }

      if (result === null || Array.isArray(result) || typeof result == 'object') return result;else return { message: result };
    }

    static call(aFunction, aInput, aPostFilter = null) {
      let body = aInput; //this.encode(aInput);
      let urlBase;
      if (window.location.hostname == 'localhost') urlBase = '/functions/';else urlBase = '/functions/';
      let url = urlBase + aFunction;

      let response = null;
      let error = null;
      let result = null;

      // The Cache-Control header may be essential for cookies to be sent back by at least some browsers, especially iOS 11.4 Safari
      let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Cache-Control': 'private, no-cache, no-store, must-revalidate'
      };
      return this.rpc_fetch(url, {
        method: 'post',
        headers: headers,
        body: JSON.stringify(body),
        credentials: 'same-origin' // note that credentials is an option, not a header
      }, aPostFilter);
    }

    static get(aFunction, aParam, aPostFilter = null) {
      let urlBase;
      if (window.location.hostname == 'localhost') urlBase = '/functions/';else urlBase = '/functions/';
      let url = urlBase + aFunction;

      // The Cache-Control header may be essential for cookies to be sent back by at least some browsers, especially iOS 11.4 Safari
      let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Cache-Control': 'private, no-cache, no-store, must-revalidate'
      };
      return this.rpc_fetch(url, {
        method: 'get',
        headers: headers,
        credentials: 'same-origin' // note that credentials is an option, not a header
      }, aPostFilter);
    }

    static async browserGet(aUrl, aParam) {
      // let urlBase;
      // if (window.location.hostname=='localhost')
      //   urlBase = '/';
      // else
      //   urlBase = '/functions/';
      // let url = urlBase+aFunction;

      let headers = {
        // 'Accept': 'application/json',
        // 'Content-Type': 'application/json',
        'Cache-Control': 'private, no-cache, no-store, must-revalidate'
      };

      let response = await (0, _regulated_fetch.default)(aUrl, {
        method: 'get',
        headers: headers,
        credentials: 'same-origin' // note that credentials is an option, not a header
      });
      // let result = response.headers.get('Content-Type').indexOf('json')>=0 ? await response.json() : await response.text();
      // if (response.ok) {
      //   if (typeof(result) == 'string')
      //     return {message: result};
      //   else
      //     return result;
      // } else {
      //   if (typeof(result) == 'string')
      //     return {error: result};
      //   else if (!result)
      //     return {error: response.statusText};
      //   else
      //     return result;
      // }
      return response;
    }

  };
});