define('c2b/components/select-light', ['exports', 'ember-select-light/components/select-light'], function (exports, _selectLight) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _selectLight.default;
    }
  });
});