define('c2b/throw-error/route', ['exports', 'error-control/dist/es/HttpErrors'], function (exports, _HttpErrors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    errorService: Ember.inject.service('error-service'),

    model(params) {
      let code = Number(params.code);
      let errorClass = _HttpErrors.default.classForStatusCode(code);
      let message = null;
      switch (code) {
        case 401:
          message = "Unfortunately this offer has expired or has already been opened on another browser.";
      }
      let e = new errorClass(message);
      throw e;
    }
  });
});