define('c2b/components/details-layout-two/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    init() {
      this._super(...arguments);
    },

    actions: {}

  });
});