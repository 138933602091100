define("c2b/application/regulated_fetch", ["exports", "error-control"], function (exports, _errorControl) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let regulated_fetch = async function (request, options, aPostFilter = null) {
    let response = await _errorControl.ErrorControl.guard(() => fetch(request, options), aPostFilter);
    if (!response || response.ok) {
      return response;
    } else {
      let errorClass = _errorControl.HttpErrors.classForStatusCode(response.status);
      let error = new errorClass();
      error.data = response;
      error = _errorControl.ErrorControl.filter(error, aPostFilter);
      if (error) throw error;else return response;
    }
  };

  exports.default = regulated_fetch;
});