define('c2b/application/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    // redirect() {
    //   this.transitionTo('details');
    // }

  });
});