define('c2b/helpers/either', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.either = either;
  function either(first, second) {
    return first || second;
  }

  exports.default = Ember.Helper.helper(either);
});